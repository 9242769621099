














import { Component, Vue, Prop } from 'vue-property-decorator';

const Arrow = () => import('@/assets/icons/Arrow.vue');

@Component({
  components: {
    Arrow,
  },
})
export default class FilterButton extends Vue {
  @Prop({ default: false }) showFilterProp!: boolean;

  handleClickFilter() {
    this.$emit('clicked-filter');
  }
}
